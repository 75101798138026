#platform-hero {
  position: relative;
  background-image: url("../Assets/platform-hero.jpg");
  background-size: cover;
  border-bottom: 0.125rem solid linear-gradient(90deg, #ff9c42, #c236ff);
  border-radius: 0 0 100px 100px;
  background-position: center;

  @media screen and (max-width: 767px) {
    border-radius: 0 0 3.125rem 3.125rem;
  }

  .img-fluid {
    margin-top: -5rem;

    @media screen and (max-width: 991px) {
      margin-top: -3rem;
    }
    @media screen and (max-width: 767px) {
      max-height: 18.75rem;
      margin: auto;
    }
  }

  .left-side {
    @media screen and (min-width: 1500px) {
      padding-top: 3rem;
    }
    @media (min-width: 1000px) and (max-width: 1500px) {
      // padding-bottom: 3rem;
    }
  }

  h2 {
    span {
      background-color: #f3ec78;
      background-image: linear-gradient(90deg, #ff9c42, #c236ff);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
  }

  height: 800px;
  @media screen and (max-width: 1500px) {
    height: 650px;
  }
  @media screen and (max-width: 1300px) {
    // height: 45rem;
    height: 550px;
  }
  @media screen and (max-width: 1200px) {
    // height: 40rem;
    height: 550px;
  }
  @media screen and (max-width: 1000px) {
    height: 37rem;
  }
  @media screen and (max-width: 800px) {
    height: 35rem;
  }
  // @media screen and (max-width: 767px) {
  //   height: 30rem;

  // }

  @media screen and (max-width: 1027px) and (min-width: 1068px) {
    .font-size-80 {
      font-size: 40px;
    }
  }
}
