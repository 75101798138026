#tag{
    margin-bottom: 0.5rem;
    font-size:14px;
    @media screen and (max-width: 525px) {
        font-size: 10px;
    
        
    }
    .h-24{
        height: 24px;
        line-height: 24px;
    }
    @media screen and (max-width: 525px) {
        .h-24{
            height: 18px;
            line-height: 18px;
        }
    }
}
