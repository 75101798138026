@import "../../../../Style/colors";
#builtforusers-section-homepage {
  
  @media screen and (max-width: 1300px) {
    .slider-right .overflow-slider {
      left: 0px !important;
    }
  }

  .second-container {
    height: 580px;
  }

  @media screen and (max-width: 1500px) {
    .second-container {
      height: 490px;
    }
    .img-blog {
      height: 250px;
    }
  }
  @media screen and (max-width: 991px) {
    .overflow-slider {
      left: 0px !important;
    }
    .gap-4 {
      gap: 10px !important;
    }
    .second-container {
      height: 400px;
    }
    .img-blog {
      height: 200px;
    }
  }
  @media screen and (max-width: 767px) {
    #builtfor-users-slide {
      min-width: 258px;
    }
  }
}

#builtfor-users-slide {
  .img-blog {
    object-fit: cover;
  }

  flex: 1 0 342px;
  // linear gradient
  background: #1f1f1f;

  .main-content-container {
  }

  @media screen and (max-width: 991px) {
    min-width: 380px;
  }

  @media screen and (max-width: 767px) {
    flex: 1 0 280px;
  }

  .main-content-container {
  }

  .tag-container {
    background: $colorDarkBlue;
    border-radius: 24px;
    padding: 0.25rem 1rem;
  }
}
