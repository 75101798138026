#UsersCreateBrand-section{
    .logo-wispr{
        position: absolute;
        top:0;
        right: 0;
        img{
            @media screen and (max-width: 1200px){
                width: 70px;
            }
        }
    }
    .font-size-40{
        @media screen and (max-width: 1400px){
            font-size: 30px;
        }
        @media screen and (max-width: 1100px){
            font-size: 27px;
        }
        @media screen and (max-width: 1050px){
            font-size: 25px;
        }
        @media screen and (max-width: 991px){
            font-size: 23px;
        }


        @media screen and (max-width: 767px){
            font-size: 30px;
        
            
        }
        @media screen and (max-width: 567px){
            font-size: 23px;
        
            
        }
    }

    .small-card{
        height: 308px ;
        @media screen and (max-width: 1400px){
            height: 250px;
        
            
        }
        @media screen and (max-width: 767px){
            height: 230px;
        
            
        }
        @media screen and (max-width: 567px){
            font-size: 200px;
        
            
        }
    }
    .large-card{
        height: 100% ;
        background-image: url('../Assets/card3bck.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 250px;


    }
    .top-card.left-col{
        // background-image: url('../Assets/card1bck.png');
        background-position: center;
        background-image: linear-gradient( 45deg ,#781310,#6D7720);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bottom-card.left-col{
        // background-image: url('../Assets/card2bck.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .top-card.right-col{
        // background-image: url('../Assets/card4bck.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bottom-card.right-col{
        // background-image: url('../Assets/card5bck.png');
        background-image: linear-gradient( 45deg ,#0B4154,#051B4B);

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

}