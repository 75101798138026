@import "../../../Styles/buttons";

#homepage-hero {
  position: relative;

  .progress-bar {
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width 0.6s ease;
    // max-width: 500px;
    height: 16px;
    margin: 1.5rem 0;
    border-radius: 20px;
    background: #2a2a2a;
    .sept-fill {
      background: linear-gradient(45deg, #c236ff, #ff9c42);
      border-radius: 24px;
    }
  }
  .box-coins {
    background-color: rgba(255, 255, 255, 0.118);
    padding: 0.4rem;
    border-radius: 0.5rem;
  }

  .presale-box {
    position: absolute;
    align-items: center;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: hsl(0deg 0% 100% / 4%);
    border-radius: 24px;
    display: flex;
    left: 50%;
    max-width: 708px;
    min-height: 240px;
    position: absolute;
    bottom: -205px;
    transform: translate(-50%);
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 767px) {
      bottom: 0;
      position: relative;
    }
  }

  background-image: url("../Assets/livepot-new-hero2.png");
  // background-image: url("../Assets/new-bg.png");
  background-size: cover;
  border-radius: 0 0 100px 100px;

  height: 800px;
  @media screen and (max-width: 1500px) {
    height: 650px;
  }
  @media screen and (max-width: 991px) {
    height: 600px;
  }

  @media screen and (max-width: 767px) {
    height: 100%;
  }

  .section-padding {
    @media screen and (max-width: 1615px) {
      padding: 6rem 2rem;
    }

    @media screen and (max-width: 991px) {
      padding: 3rem 1rem !important;
    }
    @media screen and (max-width: 567px) {
      padding: 1rem 1rem !important;
      margin-top: 0;
    }
  }
  .slider-image {
    @media screen and (max-width: 1570px) {
      max-width: 550px;
    }
    @media screen and (max-width: 1210px) {
      max-width: 425px;
    }
    @media screen and (max-width: 1050px) {
      max-width: 400px;
    }
    @media screen and (max-width: 991px) {
      max-width: 375px;
    }
    // @media screen and (max-width: 767px) {
    //   max-width: 380px;
    // }

    @media screen and (max-width: 567px) {
      max-width: 285px;
    }

    @media screen and (max-width: 390px) {
      max-width: 250px;
    }
    @media screen and (max-width: 340px) {
      max-width: 210px;
    }
    @media screen and (max-width: 295px) {
      max-width: 200px;
    }
  }

  .slider-image-small {
    @media screen and (max-width: 2500px) {
      max-width: 640px;
    }
    @media screen and (max-width: 1570px) {
      max-width: 550px;
    }
    @media screen and (max-width: 1210px) {
      max-width: 425px;
    }
    @media screen and (max-width: 1050px) {
      max-width: 400px;
    }
    @media screen and (max-width: 991px) {
      max-width: 375px;
    }
    // @media screen and (max-width: 767px) {
    //   max-width: 380px;
    // }

    @media screen and (max-width: 567px) {
      max-width: 285px;
    }

    @media screen and (max-width: 390px) {
      max-width: 250px;
    }
    @media screen and (max-width: 340px) {
      max-width: 210px;
    }
    @media screen and (max-width: 295px) {
      max-width: 200px;
    }
  }

  .develop-container {
    @media screen and (max-width: 567px) {
      align-items: center !important;
      justify-content: center !important;
      p {
        text-align: center;
      }
    }

    h2 {
      @media screen and (max-width: 320px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
    }
  }

  .btn-container {
    width: 100%;

    @media screen and (max-width: 380px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .join-btn {
    @include bordered-button(291px, 64px);
    margin-right: 0.5rem;

    @media screen and (max-width: 991px) {
      height: 56px;
    }

    @media screen and (max-width: 380px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
      height: 48px;
    }
  }

  .enroll-btn {
    @include bordered-button(291px, 64px);
    margin-left: 0.5rem;

    @media screen and (max-width: 991px) {
      height: 56px;
    }

    @media screen and (max-width: 380px) {
      margin-left: 0;
      height: 48px;
    }
  }

  .create-develop-title {
    @media screen and (max-width: 350px) {
      text-align: center;
    }
  }

  .slider-arrow {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -80px;
    top: unset;

    &.left-arrow {
      left: 44%;
    }
    &.right-arrow {
      right: 43%;
    }
  }

  .slick-slide {
    min-height: 480px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 991px) {
      height: auto !important;
      min-height: unset;
    }

    // @media screen and (max-width: 567px) {
    //   min-height: 450px;
    // }
    @media screen and (max-width: 390px) {
      min-height: 150px;
    }
  }

  .slick-track {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .slick-list {
    overflow: hidden;
    @media screen and (max-width: 991px) {
      max-height: 535px;
    }
    @media screen and (max-width: 767px) {
      max-height: 480px;
    }

    @media screen and (max-width: 567px) {
      max-height: 430px;
    }
  }

  .slider-box {
    @media screen and (max-width: 1200px) {
      justify-content: flex-end !important;
    }
    @media screen and (max-width: 767px) {
      height: auto !important;
    }
  }

  .slider-col-height {
    min-height: 480px;
    @media screen and (max-width: 991px) {
      overflow: hidden;
      // min-height: 480px;
    }
    @media screen and (max-width: 390px) {
      min-height: 390px;
    }
  }

  .custom-margin {
    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem !important;
    }

    svg {
      @media screen and (max-width: 991px) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .pos-absolute-slider {
    height: 580px;
    min-height: 580px;
    position: relative;
    transform: translate(80px, -150px);

    @media screen and (max-width: 1685px) {
      transform: translate(0px, -150px);
    }

    @media screen and (max-width: 1200px) {
      // transform: translate(0px, -295px);
    }

    @media screen and (max-width: 991px) {
      height: 640px;
      min-height: 640px;
      position: absolute;
      top: 50% !important;
      transform: translate(0, -50%);
      width: 100%;
      // left: 60px;
    }

    @media screen and (max-width: 640px) {
      height: 580px;
      min-height: 580px;
    }
    @media screen and (max-width: 390px) {
      min-height: 510px;
      height: 510px;
    }
  }

  .nft-btn {
    width: 100%;
    max-width: 280px;
    // height: 60px;
    border: none;
    outline: none;
    border-radius: 64px;
    padding: 2px;

    @media screen and (max-width: 991px) {
      height: 48px;
    }
    @media screen and (max-width: 580px) {
      height: 35px;
    }
  }
  .inner-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 64px;
    transition: all 0.3s ease-in-out;
    background-color: #000000;

    &:hover {
      background-color: transparent;
    }
  }
  @media screen and (max-width: 558px) {
    .inner-btn {
      width: 150px;
    }
  }
  @media screen and (max-width: 1027px) and (min-width: 1068px) {
    .font-size-80 {
      font-size: 40px;
    }
  }
}
