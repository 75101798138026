#use-lipo-section{
  background-image: url('../Assets/use-lipo-bck.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  .mw-md-460 {max-width: 460px;
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  .img-fluid{
    @media screen and (max-width: 767px) {
   max-height: 200px;
      margin: auto;
      
    }
  }
}
