.slider-grab {
  .slider-div {
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    cursor: grab;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-height {
    height: 550px;

    @media screen and (max-width: 1300px) {
      height: 480px;
    }
    @media screen and (max-width: 767px) {
      height: 410px;
    }
    @media screen and (max-width: 520px) {
      height: 400px;
    }

    @media screen and (max-width: 420px) {
      height: 350px;
    }
  }
  .descriptions-container {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    flex-direction: row !important;

    @media screen and (max-width: 991px) {
      margin-top: 1rem;
    }
  }
}
