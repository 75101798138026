#platform-easy-play-with-crypto-section {
  background-image: url("./Assets/bg.png");
  background-size: 100% 100%;

  @media screen and (max-width: 1500px) {
    .font-size-54{
      font-size: 47px;

    }
 

  }

  @media screen and (min-width: 768px) {
    height: 18.25rem;
  }

  .cards__container {
    &:after {
      content: "";
      background: linear-gradient(45deg, #ff9c42 0%, #c236ff 100%);
      display: block;
      height: 1px;
      margin-top: 1.5rem;

      @media screen and (max-width: 575px) {
       margin-bottom: 3rem;
      }
    }

    &--label {
      height: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      padding: 2rem 1.2rem;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.1);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      margin-bottom: 1.5rem;

      img {
        height: 2.75rem;
      }
    }

    &--wrapper {
      width: 50%;

      @media screen and (max-width: 1200px) {
        width: 70%;
      }

      @media screen and (max-width: 575px) {
        width: 50vw;
        margin: 0 auto;
        text-align: center;
      }

      h3 {
        white-space: nowrap;
      }
    }
  }

  .text__container {
    @media screen and (max-width: 767px) {
       padding-bottom: 4rem;
    }
    @media screen and (max-width: 575px) {
       padding-bottom: 1rem;
    }

    span {
      display: block;
    }

    h2 {
      @media screen and (max-width: 1200px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 767px) {
        text-align: center;
        padding-top: 3rem;
        margin-bottom: 3rem;
      }
    }
  }
}
