#builtforusers-section{
    .font-size-24{
        @media screen and (max-width: 1400px) {
            font-size: 22px;
        }
        @media screen and (max-width: 1100px) {
            font-size: 18px;
        }
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}
