@import "../../../Styles/buttons";

#TokenManagement-section {
  p.active {
    color: #fff;
    opacity: 1;

    transition: 0.5s;
    // hr {
    //   background: linear-gradient(to right,#FF9C42, #C236FF);
    //   height: 2px;
    //     opacity: 1;
    // }
    hr {
      background: linear-gradient(to right, #c236ff, #ff9c42);

      position: absolute;
      width: 100%;
      border: none;

      opacity: 1;
    }
  }
  hr {
    background: linear-gradient(to right, #ff9c42, #c236ff);
    height: 2px;
    position: absolute;
    width: 100%;
    /* height: 100%; */
    bottom: -26px;
  }
  .top-line {
    top: -26px;
  }
  p:hover {
    color: #fff;
    opacity: 1;

    transition: 0.5s;
    // hr {
    //   background: linear-gradient(to right,#FF9C42, #C236FF);
    //   height: 2px;
    //     opacity: 1;
    // }
    hr {
      background: linear-gradient(to right, #c236ff, #ff9c42);

      position: absolute;
      width: 100%;
      border: none;

      opacity: 1;
    }
  }
  .in-section-scroll {
    max-height: 672px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media screen and (max-width: 1400px) {
      max-height: 500px;
    }
    @media screen and (max-width: 1250px) {
      max-height: 400px;
    }
    @media screen and (max-width: 1080px) {
      max-height: 300px;
    }
  }

  .in-section-scroll::-webkit-scrollbar {
    display: none;
  }
  hr {
    background: linear-gradient(to right, #ff9c42, #c236ff);
    height: 2px;
  }

  p:hover {
    color: #fff;
    opacity: 1;

    transition: 0.5s;
  }

  .section-padding {
    @media screen and (max-width: 1615px) {
      padding: 6rem 2rem;
    }

    @media screen and (max-width: 991px) {
      padding: 3rem 1rem !important;
      margin-top: 3rem;
    }
    @media screen and (max-width: 567px) {
      padding: 1rem 1rem !important;
      margin-top: 0;
    }
  }
  .slider-image {
    @media screen and (max-width: 1570px) {
      max-width: 550px;
    }
    @media screen and (max-width: 1210px) {
      max-width: 425px;
    }
    @media screen and (max-width: 1050px) {
      max-width: 400px;
    }
    @media screen and (max-width: 991px) {
      max-width: 375px;
    }
    // @media screen and (max-width: 767px) {
    //   max-width: 380px;
    // }

    @media screen and (max-width: 567px) {
      max-width: 285px;
    }

    @media screen and (max-width: 390px) {
      max-width: 250px;
    }
    @media screen and (max-width: 340px) {
      max-width: 210px;
    }
    @media screen and (max-width: 295px) {
      max-width: 200px;
    }
  }

  .slider-image-small {
    @media screen and (max-width: 2500px) {
      max-width: 640px;
    }
    @media screen and (max-width: 1570px) {
      max-width: 550px;
    }
    @media screen and (max-width: 1210px) {
      max-width: 425px;
    }
    @media screen and (max-width: 1050px) {
      max-width: 400px;
    }
    @media screen and (max-width: 991px) {
      max-width: 375px;
    }
    // @media screen and (max-width: 767px) {
    //   max-width: 380px;
    // }

    @media screen and (max-width: 567px) {
      max-width: 285px;
    }

    @media screen and (max-width: 390px) {
      max-width: 250px;
    }
    @media screen and (max-width: 340px) {
      max-width: 210px;
    }
    @media screen and (max-width: 295px) {
      max-width: 200px;
    }
  }

  .develop-container {
    @media screen and (max-width: 567px) {
      align-items: center !important;
      justify-content: center !important;
      p {
        text-align: center;
      }
    }

    h2 {
      @media screen and (max-width: 320px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
    }
  }

  .btn-container {
    width: 100%;

    @media screen and (max-width: 380px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .join-btn {
    @include bordered-button(291px, 64px);
    margin-right: 0.5rem;

    @media screen and (max-width: 991px) {
      height: 56px;
    }

    @media screen and (max-width: 380px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
      height: 48px;
    }
  }

  .enroll-btn {
    @include bordered-button(291px, 64px);
    margin-left: 0.5rem;

    @media screen and (max-width: 991px) {
      height: 56px;
    }

    @media screen and (max-width: 380px) {
      margin-left: 0;
      height: 48px;
    }
  }

  .create-develop-title {
    @media screen and (max-width: 350px) {
      text-align: center;
    }
  }

  .slider-arrow {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -80px;
    top: unset;

    &.left-arrow {
      left: 44%;
    }
    &.right-arrow {
      right: 43%;
    }
  }

  .slick-slide {
    min-height: 480px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 991px) {
      height: auto !important;
      min-height: unset;
    }

    // @media screen and (max-width: 567px) {
    //   min-height: 450px;
    // }
    @media screen and (max-width: 390px) {
      min-height: 150px;
    }
  }

  .slick-track {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .slick-list {
    overflow: hidden;
    @media screen and (max-width: 991px) {
      max-height: 535px;
    }
    @media screen and (max-width: 767px) {
      max-height: 480px;
    }

    @media screen and (max-width: 567px) {
      max-height: 430px;
    }
  }

  .slider-box {
    @media screen and (max-width: 1200px) {
      justify-content: flex-end !important;
    }
    @media screen and (max-width: 767px) {
      height: auto !important;
    }
  }

  .slider-col-height {
    min-height: 480px;
    @media screen and (max-width: 991px) {
      overflow: hidden;
      // min-height: 480px;
    }
    @media screen and (max-width: 390px) {
      min-height: 390px;
    }
  }

  .custom-margin {
    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem !important;
    }

    svg {
      @media screen and (max-width: 991px) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .pos-absolute-slider {
    height: 580px;
    min-height: 580px;
    position: relative;
    transform: translate(80px, -150px);

    @media screen and (max-width: 1685px) {
      transform: translate(0px, -150px);
    }

    @media screen and (max-width: 1200px) {
      transform: translate(0px, -295px);
    }

    @media screen and (max-width: 991px) {
      height: 640px;
      min-height: 640px;
      position: absolute;
      top: 50% !important;
      transform: translate(0, -50%);
      width: 100%;
      // left: 60px;
    }

    @media screen and (max-width: 640px) {
      height: 580px;
      min-height: 580px;
    }
    @media screen and (max-width: 390px) {
      min-height: 510px;
      height: 510px;
    }
  }

  .nft-btn {
    width: 100%;
    max-width: 280px;
    // height: 60px;
    border: none;
    outline: none;
    border-radius: 64px;
    padding: 2px;

    @media screen and (max-width: 991px) {
      height: 48px;
    }
    @media screen and (max-width: 580px) {
      height: 35px;
    }
  }
  .inner-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
    background-color: #000000;

    &:hover {
      background-color: transparent;
    }
  }
  .img {
    border-radius: 1.5rem;
    height: 100%;
    object-fit: cover;
  }
}
