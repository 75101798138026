@import '../../../../Style/colors';
#tokenomics-section{
  p.active{
    color: #fff;
    opacity: 1;

    transition: 0.5s;
    // hr {
    //   background: linear-gradient(to right,#FF9C42, #C236FF);
    //   height: 2px;
    //     opacity: 1;
    // }
    hr {
      background: linear-gradient(to right, #c236ff, #ff9c42);
     
      position: absolute;
      width: 100%;
      border: none;

      opacity: 1;
    }
  }
  .launch-text{
    background: transparent linear-gradient(45deg, #FF9C42 ,#64D5DE, #C236FF) 0% 0%
    no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .restart-text{
    background: transparent linear-gradient(45deg, #FF9C42 0%,#C236FF 100%) 0% 0%
    no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .airdrop-text{
    background: transparent linear-gradient(45deg, #8F6EFF 0%, #C236FF 100%) 0% 0%
    no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width:600px) {
  .slider-right-tokenomics.second-container{
  
    height: 485px;
  }}
  @media screen and (max-width: 991px) {
 
    .gap-4{
      gap:10px!important;
    }
  }

  @media screen and (max-width: 1300px) {
    .slider-right-tokenomics .overflow-slider{
      left: 0px!important;
    }
    // .slide.h-100{
    //   height: 550px!important ;
    // }
  
  }

}
#tokenomics-slide{
  .slide{}
 
  .background-half{
    background: linear-gradient(
      to top,
      #1F1F1F 0%,
      #1F1F1F 40%,
      black 50%,
      black 100%
    );
    img{
      z-index: 99;
    }
  }

    .corner-rounded{
      position: absolute;
      background-color: #1F1F1F;
      z-index: 0;
      border-radius: 20px;
      width: 100%;
      height: 55%;
      bottom: 0;
    }
  .img-blog{
    object-fit: cover;
  }
  min-width: 342px;
  // linear gradient 
  background-color: #1F1F1F;


  .main-content-container{
    background :linear-gradient(45deg, #3A104C, #4D2E15);

  }



  @media screen and (max-width: 991px) {
    min-width: 380px;
  }

  @media screen and (max-width: 767px) {
    min-width: 300px;
  }

  .main-content-container {


  }

  .tag-container {
    background: $colorDarkBlue;
    border-radius: 24px;
    padding: 0.25rem 1rem;
  }
}


#tokenomics-first{
  .z-index{
    z-index: 99;
  }

  .background-half{
    background: linear-gradient(
      to top,
      transparent 0%,
      transparent 50%,
      black 50%,
      black 100%
    );
    img{
      z-index: 99;
    }
  }

    .corner-rounded{
      position: absolute;
      z-index: 0;
      border-radius: 20px;
      width: 100%;
      height: 156%;
      bottom: -101%;
      background: blue;
      background: linear-gradient(45deg, #3A104C, #4D2E15);
    }
  .img-blog{
    object-fit: cover;
  }
  min-width: 342px;
  // linear gradient 
  background :linear-gradient(45deg, #3A104C, #4D2E15);


  .main-content-container{
    // background :linear-gradient(45deg, #3A104C, #4D2E15);

  }



  @media screen and (max-width: 991px) {
    min-width: 380px;
  }

  @media screen and (max-width: 767px) {
    min-width: 300px;
  }

  .main-content-container {


  }

  .tag-container {
    background: $colorDarkBlue;
    border-radius: 24px;
    padding: 0.25rem 1rem;
  }
}
#tokenomics-last{
  .z-index{
    z-index: 99;
  }

  .background-half{
    background: linear-gradient(
      to top,
      transparent 0%,
      transparent 50%,
      black 50%,
      black 100%
    );
    img{
      z-index: 99;
    }
  }

    .corner-rounded{
      position: absolute;
      z-index: 0;
      border-radius: 20px;
      width: 100%;
      height: 156%;
      bottom: -101%;
      background: blue;
      

    }
    .launch{
      background: linear-gradient(45deg, #601D80, #356671 ,#785128 );

    }
    .restart{
      background: linear-gradient(45deg, #7C0677, #3E033B,#7D1F2B);

    }
    .airdrop{
      background: linear-gradient(45deg, #1D3980, #462380);

    }
  .img-blog{
    object-fit: cover;
  }
  min-width: 342px;
  // linear gradient 
  background :linear-gradient(45deg, #3A104C, #4D2E15);


  .main-content-container{
    // background :linear-gradient(45deg, #3A104C, #4D2E15);

  }



  @media screen and (max-width: 991px) {
    min-width: 380px;
  }

  @media screen and (max-width: 767px) {
    min-width: 300px;
  }

  .main-content-container {


  }

  .tag-container {
    background: $colorDarkBlue;
    border-radius: 24px;
    padding: 0.25rem 1rem;
  }
}