@font-face {
  font-family: 'Inter-Black';
  src: url('./fonts/Inter-Black.ttf') ;}

@font-face {
  font-family: 'Inter-Bold';
  src: url('./fonts/Inter-Bold.ttf') ;}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('./fonts/Inter-ExtraBold.ttf') ;}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('./fonts/Inter-ExtraLight.ttf') ;}

@font-face {
  font-family: 'Inter-Light';
  src: url('./fonts/Inter-Light.ttf') ;}

@font-face {
  font-family: 'Inter-Medium';
  src: url('./fonts/Inter-Medium.ttf') ;}

@font-face {
  font-family: 'Inter-Regular';
  src: url('./fonts/Inter-Regular.ttf') ;}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./fonts/Inter-SemiBold.ttf') ;}

@font-face {
  font-family: 'Inter-Thin';
  src: url('./fonts/Inter-Thin.ttf') ;}


.font-iner-black{
  font-family: Inter-Black, sans-serif;
  font-weight: 900;
  font-style: normal;
}
.font-iner-bold{
  font-family: Inter-Bold, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.font-iner-extrabold{
  font-family: Inter-ExtraBold, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.font-iner-extralight{
  font-family: Inter-ExtraLight, sans-serif;
  font-weight: 200;
  font-style: normal;
}
.font-iner-light{
  font-family: Inter-Light, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.font-iner-medium{
  font-family: Inter-Medium, sans-serif;
  font-weight: 500;
  font-style: normal;
}
.font-iner-regular{
  font-family: Inter-Regular, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.font-iner-semibold{
  font-family: Inter-SemiBold, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.font-iner-thin{
  font-family: Inter-Thin, sans-serif;
  font-weight: 100;
  font-style: normal;
}

