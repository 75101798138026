@import "../../../Style/colors.scss";

#platform-toggle-content {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  .large-container {
    padding: 0 30px;
  }

  position: relative;

  .nav-tabs {
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    border-bottom: none;
    background-size: 100% 2px;
    background-position: bottom 0 left 0, bottom 3px left 0;
    background-repeat: no-repeat;

    .nav-item {
      white-space: nowrap;
      border: none;
      position: relative;

      [aria-selected="true"] {
        // border-bottom: 5px solid #000 !important;
      }

      .nav-link {
        color: $colorWhite;
        font-size: 1.5rem;
        padding: 1rem 2rem 1rem 4rem;

        &:hover,
        &:focus {
          isolation: unset !important;
        }

        @media screen and (max-width: 767px) {
          font-size: 1.2rem;
          padding: 20px 50px 20px 70px;
        }
      }
      .active {
        background: linear-gradient(90deg, #ff9c42, #c236ff);
        border: none;
        outline: none;
        border-radius: 50px;
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 98%;
          height: 95%;
          background-color: #000;
          border-radius: 50px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .tab-container {
        display: flex;
        align-items: flex-end;
        position: relative;

        img {
          position: absolute;
          height: 3rem;
          left: -42px;
          top: -2px;
          margin-right: 0.2rem;

          @media screen and (max-width: 767px) {
            top: -7px;
          }
        }

        &--text {
          position: relative;

          #tag {
            position: absolute;
            width: 2.875rem;
            height: 0.875rem;
            bottom: 1.5625rem;
            right: -1.5625rem;

            @media screen and (max-width: 767px) {
              bottom: 1.05rem;
            }

            span {
              font-size: 0.5rem;
            }
          }
        }
      }
    }
  }

  .slider-right .overflow-slider,
  .slider-right.second-container {
    height: 100px !important;
    margin-bottom: 0 !important;
  }

  .large-container {
    @media screen and (max-width: 400px) {
      padding: 0;
    }
  }

  .slider-right .overflow-slider {
    left: 0;
    width: calc(100vw - max((99.5vw - 1415px) / 2, 30px));

    @media screen and (max-width: 1620px) {
    }
    @media screen and (max-width: 400px) {
      width: 100%;
      left: 0;
      right: 0;
    }
  }
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
  transition: 0ms !important;
}
