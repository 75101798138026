@import "../../../Style/colors.scss";

#platform-help-section {
  background-color: #222;
  // margin-top: 7rem;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media screen and (max-width: 991px) {
    // margin-top: 2.5rem;
  }

  .container__text {
    &--left {
      @media screen and (max-width: 575px) {
        h2 {
          padding-bottom: 1rem;
        }
      }
    }
  }

  .card__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 3rem;

    &:before {
      content: "";
      position: absolute;
      background-color: $colorDarkGray;
      width: 95%;
      height: 1px;
      top: 0;
      left: 0;

      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }

    &--head {
      padding-top: 1.5rem;
      width: 100%;
      display: flex;
      justify-content: space-between;

      img {
        @media screen and (max-width: 1504px) {
          height: 1rem;
        }
      }
    }
  }
}
