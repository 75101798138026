#platform-ranking-section {
  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 10.75rem 0;

    @media screen and (max-width: 1500px) {
      margin: 7rem 0;
    }

    @media screen and (max-width: 1024px) {
      align-items: center;
      text-align: center;
      margin-bottom: 5rem;
    }

    @media screen and (max-width: 991px) {
      margin-top: 5rem;
      margin-bottom: 3rem;
    }

    h2 {
      margin-bottom: 0;
      margin-top: 0.5rem;
    }
  }

  .card2x {
    position: relative;
    height: 342px;
    margin-bottom: 1.5rem;
    border-radius: 1.5rem;
    padding: 2.5625rem;

    @media screen and (max-width: 1200px) {
      height: 280px;
    }

    @media screen and (max-width: 767px) {
      height: 242px;
    }

    @media screen and (max-width: 575px) {
      padding: 16px;
    }

    .font-size-40 {
      @media screen and (max-width: 1160px) {
        font-size: 28px;
      }
    }

    .card2x-left {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: end;

      @media screen and (max-width: 1200px) {
        margin-bottom: 7rem;
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 0;
      }
      @media screen and (max-width: 575px) {
        height: 100%;
        align-items: center;
        margin-left: 0;
        padding-bottom: 5.5rem;
      }

      img {
        margin-bottom: 1.4375rem;
        width: 8.75rem;
        height: 5rem;

        @media screen and (max-width: 991px) {
          width: 6.75rem;
          height: 4rem;
        }

        @media screen and (max-width: 575px) {
          width: 5.75rem;
          height: 3.5rem;
        }
      }

      .font-size-40 {
        @media screen and (max-width: 1200px) {
          font-size: 28px;
        }

        @media screen and (max-width: 450px) {
          text-align: center;
        }
        @media screen and (max-width: 400px) {
          font-size: 20px;
        }
        @media screen and (max-width: 250px) {
          font-size: 16px;
        }
      }
    }

    .column-modifier {
      display: flex;
      align-items: center;
      justify-content: end;
      height: 342px;

      @media screen and (max-width: 991px) {
        height: 280px;
      }

      @media screen and (max-width: 767px) {
        height: 242px;
      }

      @media screen and (max-width: 575px) {
        justify-content: center;
        align-items: flex-start;
      }
    }

    .card2x-right {
      @media screen and (max-width: 991px) {
        display: flex;
        justify-content: end;
      }

      @media screen and (max-width: 575px) {
        justify-content: center;
      }

      // diamondu
      img {
        position: absolute;
        left: 17rem;
        bottom: 0;

        @media screen and (max-width: 1470px) {
          position: static;
          height: 100%;
          margin-top: -16rem;
          width: 100%;
        }

        @media screen and (max-width: 1200px) {
          margin-bottom: 2.5rem;
        }

        @media screen and (max-width: 991px) {
          width: 80%;
          margin-bottom: -4rem;
          margin-top: -14.5rem;
        }

        @media screen and (max-width: 770px) {
          width: 20rem;
          margin-top: -13rem;
        }

        @media screen and (max-width: 575px) {
          width: 5rem;
          margin-top: -5rem;
        }
        @media screen and (max-width: 250px) {
          display: 3rem;
        }
      }
    }

    .title-component {
      @media screen and (max-width: 400px) {
        text-align: center;
      }
    }
  }

  .card1x__container {
    height: 342px;
    background-color: #222222;
    padding: 2.5625rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: end;

    &:first-child {
      @media screen and (max-width: 575px) {
        margin-bottom: 1.5rem;
      }
    }

    @media screen and (max-width: 1200px) {
      height: 280px;
    }

    @media screen and (max-width: 991px) {
      justify-content: center;
    }

    @media screen and (max-width: 767px) {
      height: 242px;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 575px) {
      padding: 1rem;
    }

    .font-size-40 {
      @media screen and (max-width: 1200px) {
        font-size: 28px;
      }

      @media screen and (max-width: 400px) {
        font-size: 20px;
      }

      @media screen and (max-width: 250px) {
        font-size: 16px;
      }
    }

    .title-component {
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }

    span {
      display: inline;
      @media screen and (min-width: 992px) {
        display: block;
      }
    }

    &--left {
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      img {
        padding-bottom: 23px;
      }
    }
    &--right {
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      img {
        padding-bottom: 23px;
      }
    }
  }

  .card2x-long {
    background-image: url("./Assets/hero-bg01.png");
    background-size: cover;
    height: 700px;
    display: flex;
    align-items: end;
    padding: 40px 40px;

    @media screen and (max-width: 1200px) {
      height: 580px;
    }

    @media screen and (max-width: 991px) {
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      margin-top: 0rem;
      height: 280px;
    }

    @media screen and (max-width: 767px) {
      height: 242px;
    }
    @media screen and (max-width: 575px) {
      padding: 16px;
    }

    .font-size-36 {
      @media screen and (max-width: 1360px) {
        font-size: 32px;
      }
      @media screen and (max-width: 1260px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 28px;
        line-height: 1;
      }
      @media screen and (max-width: 500px) {
        font-size: 23.5px;
      }
      @media screen and (max-width: 400px) {
        font-size: 20px;
      }

      @media screen and (max-width: 250px) {
        font-size: 16px;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: end;

      @media screen and (max-width: 991px) {
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      img {
        width: 6.0313rem;
        height: 8.25rem;
        margin-bottom: 2rem;

        @media screen and (max-width: 991px) {
          width: 3.5rem;
          height: 4.5rem;
          margin-bottom: 1rem;
        }
      }

      .lipo-gradient {
        margin-bottom: 3.4375rem;
        @media screen and (max-width: 991px) {
          margin-bottom: 0.5rem;
        }
      }
      .title-component {
        line-height: 1.2 !important;

        @media screen and (max-width: 1470px) {
          font-size: 2rem !important;
          margin-right: 0;
        }
        @media screen and (max-width: 1110px) {
          font-size: 1.5rem !important;
        }
        @media screen and (max-width: 365px) {
          font-size: 1rem !important;
        }
      }

      .font-size-40 {
        @media screen and (max-width: 1470px) {
          font-size: 2rem !important;
          margin-right: 0;
        }
        @media screen and (max-width: 1110px) {
          font-size: 1.5rem !important;
        }
        @media screen and (max-width: 365px) {
          font-size: 1rem !important;
        }
      }
    }
  }

  .card1x-right {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    margin-bottom: 1.5rem;
    height: 340px;
    padding: 40px;
    background-color: #222222;

    @media screen and (max-width: 1200px) {
      height: 280px;
    }

    @media screen and (max-width: 991px) {
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 767px) {
      height: 242px;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 575px) {
      padding: 1rem;
    }

    .font-size-40 {
      @media screen and (max-width: 1200px) {
        font-size: 28px;
      }

      @media screen and (max-width: 400px) {
        font-size: 20px;
      }

      @media screen and (max-width: 250px) {
        font-size: 16px;
      }
    }
  }

  .custom__container {
    @media screen and (max-width: 991px) {
      display: flex;
    }
  }

  .card1x-right-bot {
    height: 340px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    background-image: url("./Assets/beautiful-abstract-liquid-gradient-background-with-blurred-waves-different-colors\ vertical.png");
    background-size: 100% 100%;

    @media screen and (max-width: 1200px) {
      height: 280px;
    }

    @media screen and (max-width: 991px) {
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 767px) {
      height: 242px;
      justify-content: center;
      align-items: center;
    }

    .font-size-40 {
      @media screen and (max-width: 1200px) {
        font-size: 1.75rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 20px;
      }
      @media screen and (max-width: 250px) {
        font-size: 16px;
      }
    }
  }
}
