@import "../../../../Style/colors";
#builtFor-seciton {
  @media screen and (max-width: 991px) {
    .overflow-slider {
      left: 0px !important;
    }
    .gap-4 {
      gap: 10px !important;
    }
    .second-container {
      height: 400px;
    }
    .img-blog {
      height: 200px;
    }
  }
}
#built-for-slider {
  max-width: 342px;
  .img-blog {
    object-fit: cover;
  }
  min-width: 342px;
  // linear gradient
  background: linear-gradient(45deg, #3a104c, #4d2e15);

  .main-content-container {
    background-color: #1f1f1f;
  }

  @media screen and (max-width: 991px) {
    min-width: 380px;
  }

  @media screen and (max-width: 767px) {
    min-width: 300px;
  }

  .main-content-container {
  }

  .tag-container {
    background: $colorDarkBlue;
    border-radius: 24px;
    padding: 0.25rem 1rem;
  }
}
