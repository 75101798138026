@import '../../Styles/buttons';

.errorPage {
  height: 100vh;

  .error-zixxar-logo {
    width: 250px;
    height: 250px;

    @media screen and (max-width: 991px) {
      max-width: 280px;
    }

    @media screen and (max-width: 767px) {
      max-width: 240px;
    }

    @media screen and (max-width: 567px) {
      width: 200px;
      height: 200px;
    }

    @media screen and (max-width: 460px) {
      width: 160px;
      height: 160px;
    }
    @media screen and (max-width: 325px) {
      width: 140px;
      height: 140px;
    }
  }

  .back-btn {
    @include bordered-button(291px, 64px);

    @media screen and (max-width: 991px) {
      height: 56px;
    }

    @media screen and (max-width: 380px) {
      height: 48px;
    }
  }
}
