#Roadmap-section{
    overflow: hidden;

    .roadmap{
        left: 0;
        height: 600px; 
        background-size: contain;
        @media screen and (max-width: 1500px){
            height: 450px;
        
        
        }

        @media screen and (max-width: 473px){
            margin-left: -0x;
            height: 350px;
        
            
        }
    }


}