#platform-grow-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10rem;
  
  @media screen and (max-width: 991px) {
    margin-top: 5rem;
  }

  .card-project {
    height: 342px;

    @media screen and (max-width: 991px) {
      height: 280px;
    }

    @media screen and (max-width: 767px) {
      height: 242px;
    }
  }
  .font-size-48 {
    @media screen and (max-width: 1300px) {
      font-size: 32px;
    }
    @media screen and (max-width: 991px) {
      font-size: 28px;
    }
  }
  .full-width {
    background-size: cover;
    background-image: url("./Assets/earn-money.png");
    background-position: right;
  }

  .left-col {
    background-size: cover;
    background-image: url("./Assets/sell-your-game.png");
    background-position: right;
  }
  .right-col {
    background-size: cover;
    background-image: url("./Assets/add-your-game.png");
    background-position: right;
  }
  .inner-container-layer-1 {
    position: absolute;
    width: 75%;
    left: 0;
    height: 60%;
    bottom: 0;
    background: linear-gradient(45deg, #651f7a, #7e4732);
    border-radius: 16px 16px 0 16px;
    @media screen and (max-width: 1000px) {
      width: 92%;
    }
    @media screen and (max-width: 400px) {
      width: 92%;
    }
  }
  .inner-container-layer-2 {
    position: absolute;
    width: 50%;
    left: 0;
    height: 50%;
    border-radius: 16px 16px 0 16px;

    bottom: 0;
    background: linear-gradient(45deg, #762399, #894062);
  }
  .pos-abs-lipo {
    position: absolute;
    left: 10%;
    top: 19px;
  }
  .pos-abs-price {
    position: absolute;
    right: 16%;
    top: 28%;
  }
}
