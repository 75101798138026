#first-token-section {
  background-image: url('../Assets/first-token-bck.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  .card-project {
    height: 380px;
    // background: blue;
    @media screen and (max-width:1400px) {
      height: 300px;
    
      
    }
  }
  .font-size-48{
    @media screen and (max-width:1300px) {
      font-size: 32px;
    
      
    }
    @media screen and (max-width:991px) {
      font-size: 28px;
    
      
    
      
    }
  }
  .full-width{
    background-size: cover;
    background-image: url('../Assets//section-bg.png')
  }
  .left-col {
    background-color: #222222;
  }
  .right-col {
    background:linear-gradient(45deg,#3C124F, #4D2F16);
  }
  .inner-container-layer-1{
    position: absolute;
    width: 75%;
    left: 0;
    height: 60%;
    bottom: 0;
    background:linear-gradient(45deg,#651F7A, #7E4732);
    border-radius: 16px 16px 0 16px ;
    @media screen and (max-width:1000px) {
      width: 92%;
    
      
    
      
    }
    @media screen and (max-width:400px) {
      width: 92%;
    
      
    
      
    }
  }
  .inner-container-layer-2{
    position: absolute;
    width: 50%;
    left: 0;
    height: 50%;
    border-radius: 16px 16px 0 16px ;

    bottom: 0;
    background:linear-gradient(45deg,#762399, #894062);
  }
  .pos-abs-lipo{
    position: absolute;
    left: 10%;
    top: 19px;
  }
  .pos-abs-price{
    position: absolute;
    right: 16%;
    top: 28%;
  }
}
