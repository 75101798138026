.slider-container-height {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
}

.slider-wrapper {
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  display: flex;
  align-items: flex-start;

  position: absolute;
  left: 0;
  right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card-slide {
  width: 210px;
  min-width: 210px;
  cursor: grab;
}
