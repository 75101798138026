.slider-box {
  position: relative;
  height: 640px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  // padding: 2rem 3rem;
  padding: 0 2rem;
  margin: 1rem;
  border-radius: 16px;
  transition: all 0.2s ease-in-out;
  transition-delay: 0.5s;

  @media screen and (max-width: 991px) {
    padding: 0 3rem !important;
    height: 300px;
  }

  @media screen and (max-width: 600px) {
    margin: 1rem;
  }
  @media screen and (max-width: 567px) {
    padding: 0 2rem;
  }

  .slick-item {
    padding: 1rem;
  }
}

.left-arrow,
.right-arrow {
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}
