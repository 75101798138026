$light-grey: #9d9d9d;

.color-light-grey {
  color: $light-grey;
}

$zixxar-gradient: transparent
  linear-gradient(
    270deg,
    #00a7ff 0%,
    #842bd0 35%,
    #ff007e 70%,
    #ff474b 80%,
    #ff8023 90%,
    #ffa30a 96%,
    #ffb000 100%
  )
  0% 0% no-repeat padding-box;

 $livepot-gradient :transparent
 linear-gradient(
   90deg,
   #FF9C42 0%,
   #C236FF 100%
  
 )
 0% 0% no-repeat padding-box;
$zixxar-reverse-gradient: transparent
  linear-gradient(
    90deg,
    #00a7ff 0%,
    #842bd0 35%,
    #ff007e 70%,
    #ff474b 80%,
    #ff8023 90%,
    #ffa30a 96%,
    #ffb000 100%
  )
  0% 0% no-repeat padding-box;

$light-blue: #9ca7dc;

.light-blue {
  color: $light-blue;
}
