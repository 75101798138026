.playwinearn-section {
  .token-absolute {
    top: -112%;
    // left: 50%;
    // right: 0;
    bottom: 0;
    // transform: translate(-50%, -50%);
    margin: auto;
    z-index: 99999999;
  }

  .title-component-middle {
    // display: flex;
  }
  .txt-container {
    max-width: 875px;
    margin: auto;

    @media screen and (max-width: 991px) {
      margin-bottom: 10px !important;
    }
  }

  .mobile-d-none {
    @media screen and (max-width: 1500px) {
      display: none;
    }
  }

  .success-buseiness-card-img {
    @media screen and (max-width: 567px) {
      max-width: 105px;
    }
  }

  .success-buseiness-card {
    background-color: #222;
    height: 580px;
    width: 464px;
    margin: 0 8px;
    padding: 2rem;
    border-radius: 16px;
    position: relative;

    @media screen and (max-width: 1500px) {
      height: 450px;
      // width: 364px;
    }
    @media screen and (max-width: 1500px) and (min-width: 1300px) {
      width: 30%;
      // width: 364px;
    }
    .font-size-48 {
      @media screen and (max-width: 1500px) and (min-width: 1300px) {
        font-size: 40px;
      }
    }

    .font-size-56 {
      @media screen and (max-width: 1500px) and (min-width: 1300px) {
        font-size: 48px;
      }
      @media screen and (max-width: 991px) {
        font-size: 37px;
      }
      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }

    // @media screen and (max-width:1300px) {
    //   height: 500px;
    //   width: 364px;
    // }

    @media screen and (max-width: 991px) {
      height: 450px;
    }

    @media screen and (max-width: 767px) {
      height: 400px;
      width: 364px;
    }
    @media screen and (max-width: 567px) {
      height: 380px;
      width: 300px;
    }

    @media screen and (max-width: 425px) {
      width: 260px;
      height: 350px;
      margin: 0 5px;
      padding: 2rem 1rem;
    }
    @media screen and (max-width: 365px) {
    }

    &-img {
      // position: absolute;
      // top: -50px;
      width: 170px;
    }

    &-txt {
      // padding-top: 7rem;

      @media screen and (max-width: 567px) {
        padding-top: 2rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
  }

  .slider-height {
    height: 550px !important;

    @media screen and (max-width: 991px) {
      height: 400px !important;
    }
    @media screen and (max-width: 567px) {
      height: 360px !important;
    }
    @media screen and (max-width: 365px) {
      height: 350px !important;
    }
    @media screen and (max-width: 320px) {
      height: 290px !important;
    }

    // @media screen and (max-width: 420px) {
    //   height: 350px;
    // }
  }
  .font-size-56 {
    @media screen and (max-width: 1500px) {
      font-size: 50px;
    }
    @media screen and (max-width: 567px) {
      font-size: 30px;
    }
    @media screen and (max-width: 365px) {
      font-size: 26px;
    }
    @media screen and (max-width: 320px) {
      font-size: 22px;
    }
  }
}
