// containers
.large-container {
  width: 100%;
  max-width: 1488px;
  height: 100%;
  margin: auto;
  padding: 0 24px;
  @media screen and (max-width:1500px) and (min-width:991px) {
    // padding: 0 58px;
    
  }
}
