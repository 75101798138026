@import "./colors.scss";
.zixxar-button-container {
  width: 295px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-right: 2rem;
  @media screen and (max-width: 991px) {
    width: 250px;
    height: 60px;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
    height: 55px;
    margin-right: 1rem;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 55px;
    margin-right: 0;
  }
}

.zixxar-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 99%;
  height: 96%;
  z-index: 1;
  border-radius: 12px;
  color: #ffffff;
  background-color: #000000;
  border: none;
}

.button-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  color: #ffffff;
  border-radius: 12px;
  background: $zixxar-gradient;
  border: none;
}

.zixxar-reverse-gradient {
  background: $livepot-gradient !important;
}

// ---------------------
@mixin bordered-button($maxWidth, $height) {
  width: 100%;
  max-width: $maxWidth;
  height: $height;
  border: none;
  outline: none;
  border-radius: 12px;
  background: $zixxar-reverse-gradient;
  color: #ffffff;
  padding: 2px;

  .inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #000000;
    color: #ffffff;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: transparent;
    }
  }
}


@mixin gradient-button($maxWidth, $height) {
  width: 100%;
  max-width: $maxWidth;
  height: $height;
  border: none;
  outline: none;
  border-radius: 12px;
  background: $zixxar-reverse-gradient;
  color: #ffffff;
  padding: 2px;
}