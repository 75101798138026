#UsersCreateValue-section {
  .three-col-card {
    height: 278px;
    flex: 0 0 auto;
    width: 33.33333333%;
    @media screen and (max-width: 1400px) {
      height: 250px;
    }
    @media screen and (max-width: 767px) {
      height: 200px;
    }
  }
  .five-col-card {
    background-image: url("../Assets/second.png");
    height: 278px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    @media screen and (max-width: 1400px) {
      height: 250px;
    }
    @media screen and (max-width: 767px) {
      height: 200px;
    }
  }
  .third-background {
    background-image: url("../Assets/third.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .four-col-card {
    height: 278px;
    width: 100%;
    @media screen and (max-width: 1400px) {
      height: 250px;
    }
    @media screen and (max-width: 767px) {
      height: 238px;
      width: 100%;
    }
  }

  .grey-background {
    background-color: #222222;
  }

  @media screen and (max-width: 767px) {
    .five-col-card,
    .five-col-card,
    .three-col-card {
      width: 100%;
    }
  }
  .font-size-40 {
    @media screen and (max-width: 1400px) {
      font-size: 30px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 27px;
    }
    @media screen and (max-width: 1050px) {
      font-size: 25px;
    }
    @media screen and (max-width: 991px) {
      font-size: 23px;
    }

    @media screen and (max-width: 767px) {
      font-size: 30px;
    }
    @media screen and (max-width: 567px) {
      font-size: 23px;
    }
  }

  .small-card {
    height: 308px;
    @media screen and (max-width: 1400px) {
      height: 250px;
    }
    @media screen and (max-width: 767px) {
      height: 230px;
    }
    @media screen and (max-width: 567px) {
      font-size: 200px;
    }
  }

  .top-card.left-col {
    // background-image: url('../Assets/card1bck.png');
    background-position: center;
    background-image: url("../Assets/first.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .smaller-rocket {
    @media screen and (max-width: 1400px) {
      width: 100px;
    }
  }
}
