.button-outline-style-btn {
  position: relative;
  background-color: transparent;

  min-width: var(--minWidth);
  height: var(--height);
  color: var(--color);
  transition: 0.3s;
  &.button-center {
    top: 50%;
    transform: translate(0%, -50%);
    right: 4px;
  }
  span {
    padding: var(--special-padding);
  }

  &:hover {
    color: var(--hoverColor);
    background-color: var(--bgHoverColor) !important;
  }

  &:focus-visible {
    outline: none;
  }

  .image-btn {
    padding: 0;
    // padding-right: 10px;

    // svg {
    //     // width: var(--height);
    //     height: auto;
    // }
  }

  @media screen and (max-width: 991px) {
    height: var(--heightResponsive);
    min-width: var(--minWidth991);

    // .image-btn {
    // svg {
    //     width: var(--heightResponsive);
    //     height: auto;
    // }
    // }
  }

  @media screen and (max-width: 580px) {
    min-width: var(--minWidth580);
    height: var(--heightResponsive580);
  }

  &.disabled {
    cursor: not-allowed;
  }

  .button-outline-btn {
    background: linear-gradient(90deg, #C236FA, #FE9B42 ) border-box;
    border: 2px solid transparent !important;
    border-radius: 24px;
    color: transparent;
    height: 36px;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: relative;
    transition: 0.3s;
    width: 200px;
    z-index: 1;

    position: absolute;
    height: 100%;
    width: 100%;
  }
}
