.slider-right {
  &.second-container {
    clip-path: inset(-100vw -100vw -100vw 0);
    height: 532px;

    @media screen and (max-width: 991px) {
      height: 480px;
    }

    @media screen and (max-width: 767px) {
      height: 417px;
    }
  }

  .overflow-slider {
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-snap-align: start;
    position: absolute;
    cursor: grab;
    top: 0;
    left: 25px;
    width: calc(100vw - max(calc(calc(100vw - 1415px) / 2), 30px));
    height: 100%;

    padding-right: 30px;
    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    /* Firefox */
  }

  .slide {
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
  }
}

.slider-right-tokenomics {
  &.second-container {
    clip-path: inset(-100vw -100vw -100vw 0);
    height: 574px;

    @media screen and (max-width: 991px) {
      height: 480px;
    }

    @media screen and (max-width: 767px) {
      height: 486px;
    }
  }

  .overflow-slider {
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-snap-align: start;
    position: absolute;
    cursor: grab;
    top: 0;
    left: 25px;
    width: calc(100vw - max(calc(calc(100vw - 1415px) / 2), 30px));
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    /* Firefox */
  }

  .slide {
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
  }
}
