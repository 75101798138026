.outline-button{width: 100%;
    max-width: 280px;
    border: none;
    outline: none;
    border-radius: 64px;
    padding: 2px;
.inner-btn{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 64px;
    transition: all 0.3s ease-in-out;
    background-color: #000000;

}
}