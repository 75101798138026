#first-project-section {
  margin-top: 12rem;
  @media screen and (max-width: 767px) {
    margin-top: 0rem;
  }
  .txt-container {
    max-width: 875px;
    margin: auto;

    @media screen and (max-width: 991px) {
      margin-top: 0rem;
      margin-bottom: 10px !important;
    }
  }

  .mobile-d-none {
    @media screen and (max-width: 1500px) {
      display: none;
    }
  }

  .success-buseiness-card-img {
    @media screen and (max-width: 567px) {
      max-width: 105px;
    }
  }

  .img-sizer {
    height: 55%;
    @media screen and (max-width: 1400px) {
      height: 45%;
    }
    @media screen and (max-width: 1099px) {
      height: 60%;
    }
    @media screen and (max-width: 991px) {
      height: 60%;
    }
    @media screen and (max-width: 767px) {
      height: 50%;
    }
    @media screen and (max-width: 567px) {
      height: 35%;
    }
  }
  .font-size-24 {
    @media screen and (max-width: 1501px) and (min-width: 991px) {
      font-size: 23px;
    }
  }
  .success-buseiness-card {
    background-color: #222;
    height: 580px;
    width: 464px;
    margin: 0 10px;
    padding: 2rem;
    border-radius: 16px;
    position: relative;

    @media screen and (max-width: 1500px) {
      height: 550px;
    }
    @media screen and (max-width: 1300px) {
      height: 520px;
    }

    @media screen and (max-width: 1500px) and (min-width: 1100px) {
      width: 30% !important;
      height: 550px;
    }
    // @media screen and (max-width: 1300px) {
    //   height: 500px;
    //   width: 364px;
    // }

    @media screen and (max-width: 991px) {
      height: 500px;
    }

    @media screen and (max-width: 767px) {
      height: 500px;
      width: 364px;
    }
    @media screen and (max-width: 567px) {
      height: 450px;
      width: 300px;
    }

    @media screen and (max-width: 425px) {
      width: 260px;
      // height: 350px;
      margin: 0 5px;
      padding: 2rem 1rem;
    }
    .slider-height {
      height: 550px !important;

      @media screen and (max-width: 991px) {
        height: 450px !important;
      }
      @media screen and (max-width: 767px) {
        height: 400px !important;
      }
      @media screen and (max-width: 567px) {
        height: 380px !important;
      }
    }

    &-img {
      // position: absolute;
      // top: -50px;
      width: 170px;
    }

    &-txt {
      // padding-top: 7rem;

      @media screen and (max-width: 567px) {
        padding-top: 2rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
  }
}
