.gaming-page {
    height: 100%;
    width: 100%;
    position: relative;
    @media screen and (min-width: 768px) {
        position: fixed;
    }

    @media screen and (max-width: 768px) {
        padding-top: 100px;
    }

    .col-left-title-container {
        @media screen and (min-width: 1500px) {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }

    .gaming-page-overlay {
        border-radius: 50px;
        position: absolute;

        background-color: hsla(0, 0%, 0%, 0.4);
        backdrop-filter: saturate(180%) blur(20px);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        inset: 20px;

        @media screen and (max-width: 991px) {
            inset: 0px;
            border-radius: 0;
        }
    }

    .gaming-page-descaription {
        line-height: 18px;
    }

    .gaming-page-row {
        position: relative;
    }

    .mobile-video-token {
        mix-blend-mode: lighten;
        height: 160px;
        width: 160px;
    }

    .input-subscribe {
        background: rgba(0, 0, 0, 0.3);
        border: none;
        border-radius: 64px;
        height: 56px;
        width: 100%;
        color: white;
        padding: 0 20px;
        // max-width: 708px;
        :focus {
            outline: none;
        }
    }
    .input-subscribe:focus-visible {
        outline: none;
    }

    .stay-tuned-wrapper {
        background: linear-gradient(90deg, #b75b1c 0%, #5b1b8c 100%) 0% 0%
            no-repeat padding-box;
        padding: 10px 15px;
        border-radius: 48px;
        width: fit-content;
        color: white;
    }

    .gaming-icon-group {
        background: rgba(80, 0, 100, 0.8);
        border: none;
        border-radius: 64px;
        color: white;
        width: max-content;
        a {
            color: white;
        }

        @media screen and (max-width: 768px) {
            margin: auto;
        }

        @media screen and (max-width: 576px) {
            font-size: 12px;
        }
    }
}

.gaming-page-navbar {
    // background: url('./assets/livepot-gaming-page-bg.png') no-repeat center center;
    background: #000 url("../Tokenomics/assets/bg.png") no-repeat center center;
    background-size: contain;
    // background-attachment: fixed;

    @media screen and (max-width: 991px) {
        background-size: cover;
    }

    .App {
        background: none;
    }

    .button-menu-mobile {
        display: none !important;
    }

    .links-container {
        > *:not(a):not(div) {
            display: none !important;
        }
    }
}
