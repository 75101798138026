.platform-toggle-content-cards__bg {
  &--casino {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../Assets/casino-games-bg.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--live-casino {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../Assets/live-casino.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--livestream-bets {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../Assets/livestream-bets.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--play-to-earn {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../Assets/play-to-earn.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--multiplayer-games {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../Assets/multiplayer-games.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--p2p-bet-room {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../Assets/p2p-bet.jpg");
    background-position: right;

    @media screen and (max-width: 991px) {
      background-position: center;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--sporting-bets {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    background-image: url("../Assets/sport-bets.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--esports {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../Assets/esports.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }

  &--mystery-box {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    background-image: url("../Assets/mistery-box.jpg");

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgb(0, 0, 0) 100%)
        no-repeat;
    }
  }
}

#platform-toggle-content-cards {
  padding: 14rem 0 15rem 0;
  @media screen and (max-width: 991px) {
    padding: 5rem 0 3rem 0;
  }
  @media screen and (max-width: 467px) {
    padding: 3rem 0;
  }

  .column__left {
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 50%;

    @media screen and (max-width: 991px) {
      width: 100%;
      text-align: center;
      align-items: center;
    }

    .tags__container {
      display: flex;

      #tag {
        margin-right: 0.5rem;
      }
    }

    h2 {
      margin-top: 0.625rem;
      margin-bottom: 0;
      line-height: 1;

      @media screen and (max-width: 991px) {
        width: 100%;
        text-align: center;
      }
    }

    p {
      padding-top: 1rem;
      padding-bottom: 3.75rem;
      line-height: 1.2;

      @media screen and (max-width: 991px) {
        padding-bottom: 2rem;
      }
    }
  }

  .column__right {
    cursor: pointer;
    position: absolute;
    right: 0;
    left: 35%;

    .custom__shadow {
      width: 69rem;
    }

    @media screen and (max-width: 991px) {
      position: static;
      width: 100%;
      margin-top: 3rem;
      margin-left: auto;
      margin-right: auto;
    }

    .slick-list {
      @media screen and (max-width: 467px) {
        height: 25rem;
      }
    }

    .slider-box {
      -webkit-backdrop-filter: blur(40px);
      backdrop-filter: blur(40px);
      background-color: rgb(34, 34, 34, 0.4);
      width: 342px;
      height: 450px;
      padding: 0 3rem !important;
      border: 1px solid rgb(194, 54, 255, 0.1);

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @media screen and (max-width: 991px) {
        width: 300px;
        height: 400px;
      }

      h3 {
        margin-bottom: 2.5rem !important;
      }

      img {
        height: 21.375rem;
        // margin-bottom: 1.5rem;

        @media screen and (max-width: 991px) {
          height: 300px;
        }
      }
    }

    .slider-box.slots-bg {
      position: relative;
      background-image: url("./Assets/slots-game.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.blackjack-bg {
      position: relative;
      background-image: url("./Assets/blackjack-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.roulette-bg {
      position: relative;
      background-image: url("./Assets/roulette-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.craps-bg {
      position: relative;
      background-image: url("./Assets/craps-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.backgammon-bg {
      position: relative;
      background-image: url("./Assets/backgammon-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.texas-holdem-bg {
      position: relative;
      background-image: url("./Assets/texas-holdem-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.three-card-poker-bg {
      position: relative;
      background-image: url("./Assets/three-card-poker-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.chess-bg {
      position: relative;
      background-image: url("./Assets/chess-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.giveaway-bg {
      position: relative;
      background-image: url("./Assets/giveaway-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.bingo-bg {
      position: relative;
      background-image: url("./Assets/bingo-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.achievements-bg {
      position: relative;
      background-image: url("./Assets/achievements-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .slider-box.incentives-bg {
      position: relative;
      background-image: url("./Assets/incentives-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .notify__card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: none;

      h3 {
        @media screen and (min-width: 992px) {
          font-size: 40px !important;
        }
      }

      .icon__tag {
        margin-top: 3rem;

        img {
          margin-top: 1.5rem;
          height: 120px;
        }
      }
    }
  }

  .fluid__flow {
    img {
      position: absolute;
      top: -22rem;
      left: 7rem;
      margin-top: 12rem;
      max-width: 120%;

      @media screen and (max-width: 1501px) {
        top: -17rem;
      }
      @media screen and (max-width: 1301px) {
        top: -15rem;
      }
      @media screen and (max-width: 1201px) {
        left: 3rem;
      }

      @media screen and (max-width: 991px) {
        position: static;
        max-width: 100%;
        margin-top: 0;
      }
    }
  }

  .normal__flow {
    img {
      position: absolute;
      top: -22rem;
      left: 0;
      margin-top: 12rem;
      max-width: 100%;

      @media screen and (max-width: 1501px) {
        top: -17rem;
      }
      @media screen and (max-width: 1301px) {
        top: -15rem;
      }
      @media screen and (max-width: 1201px) {
        left: 3rem;
      }

      @media screen and (max-width: 991px) {
        position: static;
        margin-top: 0;
      }
    }
  }

  .livestream__bets,
  .esports {
    img {
      left: 0;
      max-width: 100%;
    }
  }

  .sport__bets {
    img {
      left: 0;
    }
  }

  .live__casino,
  .livestream__bets {
    img {
      @media screen and (min-width: 1900px) {
        max-width: 85%;
        left: 20rem;
      }
    }
  }

  .p2p {
    img {
      @media screen and (min-width: 1900px) {
        max-width: 75%;
      }
    }
  }

  .esports,
  .mystery__box {
    img {
      @media screen and (min-width: 1900px) {
        max-width: 65%;
        left: 20rem;
      }
    }
  }
}
