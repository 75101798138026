$colorWhite: #ffffff;
$colorBlack: #000000;

$bgGray: #141414;
$bgGrayDark: #0D0D0D;
$colorDarkGray: #414042;
$colorGray: #848484;
$colorLightGray: #C4C4C4;

$navbar_main_color: #C236FF;
$navbar_dark_color: #006039;

$colorMainGreen: #0bd885;
$colorDarkGreen: #006039;

$colorDarkBlue: #031b3f;

.white-color {
  color: $colorWhite;
}

.black-color {
  color: $colorBlack;
}

.gray-color {
  color: #A5A5A5;
}

.light-gray-color {
  color: $colorLightGray;
}

.main-green-color {
  color: $colorMainGreen;
}
