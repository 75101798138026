.tokenomics-page {
    padding-bottom: 115px;
    min-height: 100vh;
    @media screen and (max-width: 1200px) {
        padding-bottom: 80px;
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 40px;
    }
    @media screen and (max-width: 575px) {
        padding-bottom: 32px;
    }

    .container-1280 {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .tokenomics-section {
        padding-bottom: 115px;
        // background-image: url("./assets/bg.png");
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: contain;
        padding-top: 240px;

        @media screen and (max-width: 1200px) {
            padding-top: 200px;
            padding-bottom: 80px;
            background-size: cover;
        }
        @media screen and (max-width: 767px) {
            padding-top: 160px;
            padding-bottom: 40px;
        }
        @media screen and (max-width: 575px) {
            padding-top: 120px;
            padding-bottom: 32px;
        }

        .tokenomics-title {
            margin-bottom: 48px;
            @media screen and (max-width: 1200px) {
                text-align: center;
            }
        }
        .total-supply {
            background: linear-gradient(32.28deg, #bc7738 0%, #9130bb 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 25px;
            padding-bottom: 25px;
            border-radius: 16px;
            @media screen and (max-width: 767px) {
                padding: 16px;
            }
        }

        .initial-total-supply {
            background-color: #1a1a1a;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 25px;
            padding-bottom: 25px;
            border-radius: 16px;
            @media screen and (max-width: 767px) {
                padding: 16px;
            }
        }

        .tokenomics-card {
            min-height: 100px;
            border-radius: 1rem;
            padding-left: 1rem;
            padding-right: 21px;
            flex: 1 1 calc(50% - 1rem);
            max-width: 100%;
            @media screen and (max-width: 767px) {
                padding: 16px;
            }
        }
        .tokenomics-container {
            @media screen and (max-width: 1200px) {
                flex-direction: column;
            }
            .left-side {
                margin-right: 48px;
                @media screen and (max-width: 1200px) {
                    margin-right: 0;
                }
            }
        }
        .pie-chart {
            max-width: 588px;
            @media screen and (max-width: 1300px) {
                max-width: 488px;
            }
            @media screen and (max-width: 1200px) {
                margin-top: 48px;
            }
            @media screen and (max-width: 575px) {
                margin-top: 32px;
            }
        }
    }

    .smart-contract-section {
        .smart-contract-card {
            background-color: #1a1a1a;
            padding: 32px;
            border-radius: 32px;
            @media screen and (max-width: 767px) {
                padding: 16px;
            }
            .badge {
                background-color: #00ff58;
                padding: 4px 12px;
                gap: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                width: -moz-fit-content;
                border-radius: 80px;
                color: #1a1a1a;
                margin-bottom: 24px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 16px;
                }
            }
        }

        .input-address {
            border-radius: 16px;
            border: 1.5px solid
                var(--Opacity-Colors-Black-10, rgba(0, 0, 0, 0.1));
            background: var(--Main-Colors-Light, #f5f5f5);
            /* Background blur / xl */
            backdrop-filter: blur(20px);
            height: 56px;
            width: 100%;
            color: #1a1a1a;
            padding: 0 20px;
            // max-width: 708px;
            :focus {
                outline: none;
            }

            @media screen and (max-width: 767px) {
                height: 40px;
                padding: 0 8px;
            }
        }
        .input-address:focus-visible {
            outline: none;
            box-shadow: none;
        }

        .copy-btn {
            right: 0 !important;
            @media screen and (max-width: 767px) {
                height: 40px;
                width: 40px !important;
                right: -2px !important;
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
