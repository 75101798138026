@import "../../../Style/colors.scss";

#platform-play-with-crypto-section {
  .cards-container {
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 410px;
    @media (max-width: 768px) {
      top: 300px;
    }
    @media (max-width: 576px) {
      top: 200px;
    }
    &:hover {
      @media (min-width: 576px) {
        .card-2 {
          transform: rotate(10deg) translate(200px, -30px);
          transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
        }
        .card-3 {
          transform: rotate(-10deg) translate(-200px, -30px);
          transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
        }
      }
    }

    .card-container {
      cursor: pointer;
      @media screen and (max-width: 768px) {
        width: 50%;
      }
    }

    .card-1 {
      z-index: 1;
    }

    .card-3 {
      @media screen and (max-width: 576px) {
        transform: translateX(-5rem);
      }
      transform: translateX(-10rem);
      transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
    }

    .card-2 {
      @media screen and (max-width: 576px) {
        transform: translateX(5rem);
      }
      transform: translateX(10rem);
      transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
    }
  }

  .background-section {
    background-image: url("./Assets/play-with-crypto-bg2.png");
    height: 800px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 7.5rem;
    border-radius: 0 0 100px 100px;
    background-position: center;

    .cards__img {
      transform: translate(0px, 148px);
      @media screen and (max-width: 768px) {
        transform: translate(0px, 0px);
      }
    }
  }

  .container-md {
    .token__img {
      // border: 1px solid white;
      @media screen and (max-width: 768px) {
        transform: translateY(4.5rem);
      }
      @media screen and (max-width: 575px) {
        transform: translateY(1rem);
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 5rem;
  }

  .cards__container {
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }

  .title__container {
    margin-top: 5.6875rem;
  }

  .description__container {
    p {
      color: $colorLightGray;
      margin-top: 5.5rem;
      margin-bottom: 1.75rem;

      @media screen and (max-width: 767px) {
        margin-top: 2.5rem;
      }
    }
  }

  .cryptocurrencies__container {
    position: relative;
    z-index: 1;

    @media screen and (max-width: 767px) {
      margin-top: 0rem;
    }

    &--img {
      position: absolute;
      right: 50%;
      transform: translate(50%, -50%);
      height: 275px;
      width: 275px;

      @media screen and (max-width: 1200px) {
        height: 200px;
        width: 200px;
      }

      @media screen and (max-width: 767px) {
        transform: translate(50%, -125%);
      }

      @media screen and (max-width: 575px) {
        height: 150px;
        width: 150px;
        transform: translate(50%, -115%);
      }
      @media screen and (max-width: 355px) {
        height: 100px;
        width: 100px;
        transform: translate(50%, -100%);
      }
    }

    &--left {
      position: relative;
      padding-top: 5rem;
      padding-right: 5rem;
      background: radial-gradient(
        circle at top right,
        transparent 9rem,
        $colorBlack 1vw
      );

      @media screen and (max-width: 1200px) {
        background: radial-gradient(
          circle at top right,
          transparent 7rem,
          $colorBlack 1vw
        );
      }

      @media screen and (max-width: 767px) {
        padding: 0 1.5rem;
        background: #000;
      }

      .details__container {
        img {
          margin-top: 5rem;

          @media screen and (max-width: 1200px) {
            height: 30vw;
          }

          @media screen and (max-width: 767px) {
            height: 50vw;
          }
        }

        span {
          display: block;
          margin-top: 7rem;

          @media screen and (max-width: 767px) {
            margin-top: 5rem;
          }
        }
      }
      .text__container {
        h3 {
          margin-top: 2rem;
        }

        p {
          margin-top: 0.5rem;
          color: $colorLightGray;
          padding-bottom: 5rem;
        }
      }
    }

    &--right {
      padding-top: 4.5rem;
      padding-left: 5rem;
      background: radial-gradient(
        circle at top left,
        transparent 9rem,
        $bgGrayDark 1vw
      );

      @media screen and (max-width: 1200px) {
        background: radial-gradient(
          circle at top left,
          transparent 7rem,
          $bgGrayDark 1vw
        );

        @media screen and (max-width: 767px) {
          padding: 0 1.5rem;
          background: $bgGrayDark;
        }
      }

      .details__container {
        img {
          margin-top: 5rem;

          @media screen and (max-width: 1200px) {
            height: 30vw;
          }
        }
        span {
          display: block;

          &:first-child {
            margin-top: 7rem;

            @media screen and (max-width: 767px) {
              margin-top: 5rem;
            }
          }
        }
      }
      .text__container {
        h3 {
          margin-top: 2rem;
        }
        p {
          margin-top: 0.5rem;
          color: $colorLightGray;
          padding-bottom: 5rem;
        }
      }
    }
  }

  .section1 {
    border: 1px solid white;
    background-image: url("./Assets/play-with-crypto-bg2.png");

    // background-size: 100%;
    background-repeat: no-repeat;
  }

  .section2 {
    border: 1px solid red;
  }
}
